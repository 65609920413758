<template>
  <VCard class="cardPrimary " style="min-height: 90% !important;" tile>
    <KioskGuardUserInteractionRequired @user-inactif="close" />
    <KioskAdminSettingForm
      ref="settingForm"
      @submitted="onAdminSettingFormSubmitted"
    >
      <template #uninstall="{ setUninstall }">
        <KioskDialogConfirm
          immediate
          :confirm="uninstall"
          @close="() => setUninstall(false)"
        >
          <template #default="{ confirm, cancel }">
            <KioskUninstallCard
              :loading="loading"
              @click:confirm="confirm"
              @click:cancel="cancel"
            />
          </template>
        </KioskDialogConfirm>
      </template>
      <template #error>
        <VAlert
          :value="uninstallError != null"
          type="error"
          transition="scale-transition"
        >
          <span v-text="$t(`app.kiosk.errors.NETWORK_ERROR.alert.text`)" />
        </VAlert>
      </template>
    </KioskAdminSettingForm>
  </VCard>
</template>

<script>
import KioskGuards from '@/guards'
import KioskDialogConfirm from '@/components/KioskDialogConfirm'
import KioskAdminSettingForm from './components/KioskAdminSettingForm'
import KioskUninstallCard from './components/KioskUninstallCard'

export default {
  name: 'KioskAdminSettings',
  components: {
    KioskDialogConfirm,
    KioskUninstallCard,
    KioskAdminSettingForm,
    ...KioskGuards
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('kiosk/logout')
    await next()
  },
  data() {
    return {
      loading: false,
      uninstallError: null
    }
  },
  computed: {
    settingFormControl() {
      const { form } = this.$refs.settingForm
      return form
    }
  },
  mounted() {
    this.settingFormControl.on('change:field', this.updateToolbar)
    this.updateToolbar()
  },
  methods: {
    async close() {
      this.$router.push({ name: 'dial.index' })
    },
    onUninstallLicense(uninstallOk) {
      if (uninstallOk) {
        this.$router.push({ name: 'install.index' })
      }
    },
    onAdminSettingFormSubmitted(submitOk) {
      if (submitOk) {
        this.$router.push({ name: 'dial.index' })
      }
    },
    async saveSetting() {
      try {
        await this.$refs.settingForm.submit()
        this.onAdminSettingFormSubmitted(true)
      } catch (err) {
        this.onAdminSettingFormSubmitted(false)
      }
    },
    async uninstall() {
      this.loading = true
      this.uninstallError = null
      try {
        await this.$store.dispatch('kiosk/uninstall')
        this.onUninstallLicense(true)
      } catch (err) {
        this.$log.debug(err)
        this.uninstallError = err
        this.loading = false
        this.onUninstallLicense(false)
      }
    },
    updateToolbar() {
      this.$emit('change:toolbar-actions', {
        close: {
          icon: 'close',
          trigger: this.close,
          dataHeapId: 'form_close'
        },
        save: {
          text: this.$t('app.kiosk.admin.save.btn.text'),
          btn: {
            disabled: this.settingFormControl.$v.$invalid,
            color: 'primary',
            dataHeapId: 'form_save'
          },
          trigger: this.saveSetting
        }
      })
    }
  }
}
</script>
